import * as THREE from 'three';

const TRAIL_LENGTH = 50;
const TRAIL_SPACING = 4;
const FADE_DURATION = 5000;

export const createTrailSegment = () => {
  const geometry = new THREE.BufferGeometry();
  const material = new THREE.LineBasicMaterial({ 
    color: 0x000000,
    linewidth: 3,
    transparent: true, 
    opacity: 0.7,
    depthWrite: false
  });
  return new THREE.Line(geometry, material);
};

export const updatePlayerTrail = (player, sceneRef, currentTime) => {

  if (!player || !player.mesh) {
    console.error('Invalid player object or missing mesh');
    return;
  }

  const { mesh, trailSegments, isAccelerating, wasAccelerating } = player;

  if (!mesh.position) {
    console.error('Player mesh is missing position property');
    return;
  }

  if (isAccelerating) {
    // Calculate trail positions
    const trailOffsetX = Math.sin(mesh.rotation.y) * TRAIL_SPACING / 2;
    const trailOffsetZ = -Math.cos(mesh.rotation.y) * TRAIL_SPACING / 2;

    const leftTrailPos = new THREE.Vector3(mesh.position.x + trailOffsetX, 1, mesh.position.z + trailOffsetZ);
    const rightTrailPos = new THREE.Vector3(mesh.position.x - trailOffsetX, 1, mesh.position.z - trailOffsetZ);

    // If we just started accelerating or there are no trail segments, create new ones
    if (!wasAccelerating || !trailSegments || trailSegments.length === 0) {
      const leftSegment = createTrailSegment();
      const rightSegment = createTrailSegment();
      if (!player.trailSegments) player.trailSegments = [];
      player.trailSegments.push({ 
        left: leftSegment, 
        right: rightSegment, 
        leftPositions: [], 
        rightPositions: [],
        creationTime: currentTime
      });
      sceneRef.current.add(leftSegment);
      sceneRef.current.add(rightSegment);
    }

    // Add positions to the current trail segments
    const currentSegment = player.trailSegments[player.trailSegments.length - 1];
    currentSegment.leftPositions.push(leftTrailPos);
    currentSegment.rightPositions.push(rightTrailPos);

    // Limit the length of each trail segment
    if (currentSegment.leftPositions.length > TRAIL_LENGTH) {
      currentSegment.leftPositions.shift();
      currentSegment.rightPositions.shift();
    }

    // Update the geometry of the current trail segments
    currentSegment.left.geometry.setFromPoints(currentSegment.leftPositions);
    currentSegment.right.geometry.setFromPoints(currentSegment.rightPositions);
  }

  // Update trail fading
  if (player.trailSegments) {
    player.trailSegments.forEach((segment, index) => {
      const age = currentTime - segment.creationTime;
      const opacity = Math.max(0, 0.7 - (age / FADE_DURATION) * 0.7);
      segment.left.material.opacity = opacity;
      segment.right.material.opacity = opacity;

      // Remove fully faded trails
      if (opacity === 0) {
        sceneRef.current.remove(segment.left);
        sceneRef.current.remove(segment.right);
        player.trailSegments.splice(index, 1);
      }
    });
  }
};