import React, { useState } from 'react';

const DebugWindow = ({ 
  players, 
  currentPlayerId, 
  playerPosition, 
  trees,
  bananas,
  checkpoints,
  currentCheckpoint,
  raceStatus,
  raceTime,
  nextRaceStartTime,
  currentRaceState
}) => {
  const [isMinimized, setIsMinimized] = useState(true);

  const toggleMinimize = () => {
    setIsMinimized(!isMinimized);
  };
  return (
    <div className={`fixed top-0 right-0 bg-black bg-opacity-50 text-white p-4 m-4 rounded-lg transition-all duration-300 ease-in-out ${isMinimized ? 'w-12 h-12 overflow-hidden' : 'max-w-sm max-h-[80vh] overflow-auto'}`}>
      <button 
        onClick={toggleMinimize}
        className="absolute top-2 right-2 text-white hover:text-gray-300 focus:outline-none"
      >
        {isMinimized ? '🪲' : '✖️'}
      </button>
      {!isMinimized && (
        <>
          <h2 className="text-lg font-bold mb-2">Debug Info</h2>
          <div className="space-y-2">
            <div>
              <h3 className="font-semibold">Current Player</h3>
              <p>ID: {currentPlayerId || 'N/A'}</p>
              <p>Position: ({playerPosition?.x?.toFixed(2) || 0}, {playerPosition?.y?.toFixed(2) || 0})</p>
              <p>Current Checkpoint: {currentCheckpoint}</p>
            </div>
            <div>
              <h3 className="font-semibold">Players</h3>
              {Object.entries(players || {}).map(([id, player]) => (
                <div key={id} className="ml-2 mb-2">
                  <p>ID: {id}</p>
                  <p>Position: ({player.mesh?.position?.x?.toFixed(2) || 0}, {player.mesh?.position?.y?.toFixed(2) || 0}, {player.mesh?.position?.z?.toFixed(2) || 0})</p>
                  <p>Rotation: {(player.mesh?.rotation?.y * 180 / Math.PI).toFixed(2)}°</p>
                  <p>Speed: {player.speed?.toFixed(2) || 0}</p>
                  <p>Direction: ({player.directionX?.toFixed(2) || 0}, {player.directionZ?.toFixed(2) || 0})</p>
                  <p>Accelerating: {player.isAccelerating ? 'Yes' : 'No'}</p>
                  <p>Banana Effect: {player.isBananaEffect ? 'Yes' : 'No'}</p>
                  <p>Color: <span className="inline-block w-4 h-4 mr-1" style={{backgroundColor: player.color || '#000000'}}></span>{player.color || 'N/A'}</p>
                  <p>Current Checkpoint: {player.currentCheckpoint || 'N/A'}</p>
                  <p>Current Lap: {player.currentLap || 0}</p>
                </div>
              ))}
            </div>
            <div>
              <h3 className="font-semibold">Environment</h3>
              <p>Trees: {trees?.length || 0}</p>
              <p>Bananas: {bananas?.length || 0}</p>
            </div>
            <div>
              <h3 className="font-semibold">Checkpoints</h3>
              <p>Total Checkpoints: {checkpoints?.length || 0}</p>
              {checkpoints?.map((checkpoint, index) => (
                <div key={index} className="ml-2">
                  <p>Checkpoint {index}: ({checkpoint?.x?.toFixed(2) || 0}, {checkpoint?.y?.toFixed(2) || 0})</p>
                </div>
              ))}
            </div>
            <div>
              <h3 className="font-semibold">Race Info</h3>
              <p>Status: {raceStatus || 'N/A'}</p>
              <p>Time: {((raceTime || 0) / 1000).toFixed(2)} seconds</p>
              <p>Next Race Start: {new Date(nextRaceStartTime).toLocaleTimeString()}</p>
              <p>Current Race State: {currentRaceState}</p>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default DebugWindow;