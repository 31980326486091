import { useEffect, useCallback } from 'react';
import * as THREE from 'three';
import { createPlayerMesh, updatePlayerMesh } from '../utils/playerMeshUtils';
import { createBananaMesh, animateBananaDrop } from '../utils/bananaUtils';
import { createLeafParticles } from '../utils/treeUtils';

const useSocketHandlers = (
  socket,
  initThreeJS,
  setTrees,
  setBananas,
  setTrack,
  setRaceStatus,
  setRaceTime,
  setCountdown,
  raceStartTimeRef,
  playerIdRef,
  playersRef,
  leafParticlesRef,
  sceneRef,
  arenaSizeRef,
  updatePlayerReference,
  setNextCheckpoint,
  setCurrentLap,
  setNextRaceStartTime,
  setCurrentRaceState,
  setLapTime
) => {
  const handleGameState = useCallback((gameState) => {
    if (!sceneRef.current || !arenaSizeRef.current) return;
    setBananas(gameState.bananas);
    setNextRaceStartTime(gameState.nextRaceStartTime);
    setCurrentRaceState(gameState.currentRaceState);
    setRaceTime(gameState.currentRaceTime);
    setRaceStatus(gameState.currentRaceState);
    setLapTime(gameState.players.find(p => p.id === socket.id)?.lapTime || 0);

    gameState.players.forEach(state => {
      if (!playersRef.current[state.id]) {
        const playerMesh = createPlayerMesh(state.id, state.color);
        playersRef.current[state.id] = {
          mesh: playerMesh,
          trailSegments: [],
          lastSpeed: 0,
          isAccelerating: false,
          wasAccelerating: false,
          lastPosition: new THREE.Vector3(),
          targetRotation: 0,
          color: state.color
        };
        sceneRef.current.add(playerMesh);
        if (state.id === playerIdRef.current) {
          updatePlayerReference(playerMesh);
        }
      }
    
      const player = playersRef.current[state.id];
      player.wasAccelerating = player.isAccelerating;
      
      // Determine if the player is accelerating based on speed change
      const acceleration = state.speed - player.lastSpeed;
      player.isAccelerating = acceleration > 0.01; // Consider accelerating if there's a significant increase in speed

      player.lastSpeed = state.speed;

      updatePlayerMesh(player, state, arenaSizeRef.current);
    });

    // Remove players that are no longer in the game state
    Object.keys(playersRef.current).forEach(playerId => {
      if (!gameState.players.find(p => p.id === playerId)) {
        const playerToRemove = playersRef.current[playerId];
        sceneRef.current.remove(playerToRemove.mesh);
        delete playersRef.current[playerId];
      }
    });

    setBananas(gameState.bananas);
  }, [sceneRef, arenaSizeRef, playersRef, setBananas, playerIdRef, updatePlayerReference, setTrees, setNextRaceStartTime, setCurrentRaceState, setRaceTime, setRaceStatus, setLapTime]);

  const handleTreeCollision = useCallback((data) => {
    if (!sceneRef.current || !arenaSizeRef.current) return;
  
    setTrees(prevTrees => prevTrees.map(tree => 
      tree.id === data.treeId 
        ? { ...tree, lastCollisionTime: Date.now() } 
        : tree
    ));
  
    // Create leaf particles for the collided tree
    const collidedTree = leafParticlesRef.current[data.treeId];
    if (collidedTree) {
      const worldX = collidedTree.position.x;
      const worldZ = collidedTree.position.z;
  
      // Remove existing particles for this tree if they exist
      if (leafParticlesRef.current[data.treeId]) {
        sceneRef.current.remove(leafParticlesRef.current[data.treeId]);
        delete leafParticlesRef.current[data.treeId];
      }
  
      // Create new particles
      const particles = createLeafParticles({ x: worldX, y: 0, z: worldZ });
      particles.userData.treeId = data.treeId;
      leafParticlesRef.current[data.treeId] = particles;
      sceneRef.current.add(particles);
    }
  }, [sceneRef, arenaSizeRef, leafParticlesRef, setTrees]);

  const handleCheckpointPassed = useCallback((data) => {
    if (data.playerId === playerIdRef.current) {
      setNextCheckpoint(data.checkpoint);
      setCurrentLap(data.lap);
    }
  }, [playerIdRef, setNextCheckpoint, setCurrentLap]);

  const handleUpdateTree = useCallback((updatedTree) => {
    setTrees(prevTrees => prevTrees.map(tree => 
      tree.id === updatedTree.id ? { ...tree, ...updatedTree } : tree
    ));
  }, [setTrees]);

  useEffect(() => {
    if (!socket) return;

    const handleInit = (data) => {
      playerIdRef.current = data.id;
      initThreeJS(data.arenaSize);
      setTrees(data.currentGameState.trees);
    };

    const handleBananaDrop = (data) => {
      if (!sceneRef.current || !arenaSizeRef.current) return;

      const bananaMesh = createBananaMesh();
      const worldX = data.x - arenaSizeRef.current.width / 2;
      const worldZ = -(data.y - arenaSizeRef.current.height / 2);
    
      const startPosition = new THREE.Vector3(worldX, 15, worldZ);
      const randomAngle = Math.random() * Math.PI * 2;
      const popDistance = 8;
      const endPosition = new THREE.Vector3(
        worldX + Math.cos(randomAngle) * popDistance,
        0.75,
        worldZ + Math.sin(randomAngle) * popDistance
      );
    
      bananaMesh.position.copy(startPosition);
      sceneRef.current.add(bananaMesh);
    
      animateBananaDrop(bananaMesh, startPosition, endPosition, () => {
        setBananas(prevBananas => [...prevBananas, { id: data.id, x: data.x, y: data.y }]);
      });
    };

    const handleBananaCollision = (data) => {
      setBananas(prevBananas => prevBananas.filter(banana => banana.id !== data.bananaId));
    };

    const handleInitTrack = (trackData) => {
      setTrack(trackData);
    };

    const handlePlayerReset = (data) => {
      if (playersRef.current[data.playerId]) {
        const player = playersRef.current[data.playerId];
        player.mesh.position.set(
          data.position.x - arenaSizeRef.current.width / 2,
          0,
          -(data.position.y - arenaSizeRef.current.height / 2)
        );
        player.mesh.rotation.y = -data.angle;
      }
    };

    const handleCountdownStarted = (data) => {
      if (data.playerId === playerIdRef.current) {
        setRaceStatus('countdown');
        setCountdown(Math.ceil(data.duration / 1000));
      }
    };

    const handleRaceStarted = (data) => {
      if (data.playerId === playerIdRef.current) {
        setRaceStatus('racing');
        setRaceTime(0);
        raceStartTimeRef.current = data.startTime;
      }
    };

    const handleRaceFinished = (data) => {
      if (data.playerId === playerIdRef.current) {
        setRaceStatus('finished');
        setRaceTime(data.raceTime);
      }
    };

    const handlePlayerLeft = (leftPlayerId) => {
      if (playersRef.current[leftPlayerId]) {
        // Remove the player's mesh from the scene
        if (sceneRef.current && playersRef.current[leftPlayerId].mesh) {
          sceneRef.current.remove(playersRef.current[leftPlayerId].mesh);
        }
        
        // Remove any other associated objects (e.g., trails)
        if (playersRef.current[leftPlayerId].trailSegments) {
          playersRef.current[leftPlayerId].trailSegments.forEach(segment => {
            if (sceneRef.current) {
              sceneRef.current.remove(segment.left);
              sceneRef.current.remove(segment.right);
            }
          });
        }
        
        // Delete the player from the players object
        delete playersRef.current[leftPlayerId];
        console.log(`Player ${leftPlayerId} removed from the game`);
      }
    };

    socket.on('init', handleInit);
    socket.on('gameState', handleGameState);
    socket.on('bananaDrop', handleBananaDrop);
    socket.on('bananaCollision', handleBananaCollision);
    socket.on('initTrack', handleInitTrack);
    socket.on('playerReset', handlePlayerReset);
    socket.on('countdownStarted', handleCountdownStarted);
    socket.on('raceStarted', handleRaceStarted);
    socket.on('raceFinished', handleRaceFinished);
    socket.on('treeCollision', handleTreeCollision);
    socket.on('checkpointPassed', handleCheckpointPassed);
    socket.on('updateTree', handleUpdateTree);
    socket.on('playerLeft', handlePlayerLeft);

    return () => {
      socket.off('init', handleInit);
      socket.off('gameState', handleGameState);
      socket.off('bananaDrop', handleBananaDrop);
      socket.off('bananaCollision', handleBananaCollision);
      socket.off('initTrack', handleInitTrack);
      socket.off('playerReset', handlePlayerReset);
      socket.off('countdownStarted', handleCountdownStarted);
      socket.off('raceStarted', handleRaceStarted);
      socket.off('raceFinished', handleRaceFinished);
      socket.off('treeCollision', handleTreeCollision);
      socket.off('checkpointPassed', handleCheckpointPassed);
      socket.off('updateTree', handleUpdateTree);
      socket.off('playerLeft', handlePlayerLeft);
    };
  }, [
    socket,
    initThreeJS,
    setTrees,
    setBananas,
    setTrack,
    setRaceStatus,
    setRaceTime,
    setCountdown,
    handleGameState,
    handleTreeCollision,
    arenaSizeRef,
    leafParticlesRef,
    playerIdRef,
    playersRef,
    raceStartTimeRef,
    sceneRef,
    handleCheckpointPassed,
    handleUpdateTree
  ]);

  return null;
};

export default useSocketHandlers;