import React from 'react';

const RaceControls = ({ socket, raceStatus, raceTime, lapTime, countdown, nextRaceStartTime }) => {
  const handleResetToStart = () => {
    socket.emit('resetToStart');
  };

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60000);
    const seconds = ((time % 60000) / 1000).toFixed(2);
    return `${minutes}:${seconds.padStart(5, '0')}`;
  };

  const getTimeUntilNextRace = () => {
    const timeUntilNextRace = nextRaceStartTime - Date.now();
    return Math.max(0, Math.floor(timeUntilNextRace / 1000));
  };

  return (
    <div className="absolute top-4 left-4 space-y-2">
      <button
        className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition-colors"
        onClick={handleResetToStart}
      >
        Reset to Start
      </button>
      {raceStatus === 'notStarted' && (
        <div className="bg-white p-4 rounded shadow">
          <h2 className="text-xl font-bold">Next Race In</h2>
          <p className="text-2xl">{getTimeUntilNextRace()} seconds</p>
        </div>
      )}
      {raceStatus === 'countdown' && (
        <div className="bg-white p-4 rounded shadow">
          <h2 className="text-2xl font-bold">Get Ready!</h2>
          <p className="text-4xl">{countdown}</p>
        </div>
      )}
      {raceStatus === 'racing' && (
        <div className="bg-white p-4 rounded shadow space-y-2">
          <div>
            <h2 className="text-xl font-bold">Race Time</h2>
            <p className="text-2xl">{formatTime(raceTime)}</p>
          </div>
          <div>
            <h2 className="text-xl font-bold">Lap Time</h2>
            <p className="text-2xl">{formatTime(lapTime)}</p>
          </div>
        </div>
      )}
      {raceStatus === 'finished' && (
        <div className="bg-white p-4 rounded shadow">
          <h2 className="text-xl font-bold">Race Finished!</h2>
          <p className="text-2xl">Time: {formatTime(raceTime)}</p>
        </div>
      )}
    </div>
  );
};

export default RaceControls;