import { useEffect, useRef } from 'react';
import { createTreeMesh, animateLeaves } from './utils/treeUtils';

const TreeComponent = ({ trees, sceneRef, arenaSizeRef, leafParticlesRef }) => {
  const treeMeshesRef = useRef({});
  const animationFrameRef = useRef();

  useEffect(() => {
    if (!sceneRef.current || !arenaSizeRef.current) return;

    console.log("Updating trees:", trees);

    // Create or update tree meshes
    trees.forEach(tree => {
      if (!treeMeshesRef.current[tree.id]) {
        const treeMesh = createTreeMesh();
        if (treeMesh) {
          const worldX = tree.x - arenaSizeRef.current.width / 2;
          const worldZ = -(tree.z - arenaSizeRef.current.height / 2);
          treeMesh.position.set(worldX, 0, worldZ);
          treeMesh.scale.set(5, 5, 5);
          
          treeMeshesRef.current[tree.id] = treeMesh;
          sceneRef.current.add(treeMesh);
        } else {
          console.error(`Failed to create tree mesh for tree ${tree.id}`);
        }
      } else {
        // Update existing tree position if necessary
        const treeMesh = treeMeshesRef.current[tree.id];
        const worldX = tree.x - arenaSizeRef.current.width / 2;
        const worldZ = -(tree.y - arenaSizeRef.current.height / 2);
        treeMesh.position.set(worldX, 0, worldZ);
      }
    });

    // Remove any trees that no longer exist
    Object.keys(treeMeshesRef.current).forEach(treeId => {
      if (!trees.find(t => t.id === treeId)) {
        const treeMesh = treeMeshesRef.current[treeId];
        if (treeMesh) {
          sceneRef.current.remove(treeMesh);
          if (treeMesh.geometry) treeMesh.geometry.dispose();
          if (treeMesh.material) {
            if (Array.isArray(treeMesh.material)) {
              treeMesh.material.forEach(material => material.dispose());
            } else {
              treeMesh.material.dispose();
            }
          }
          delete treeMeshesRef.current[treeId];
        }
      }
    });

    // Animation loop for leaf particles
    const animate = () => {
      Object.entries(leafParticlesRef.current).forEach(([id, particles]) => {
        if (particles && particles.geometry && particles.material) {
          const shouldKeep = animateLeaves(particles, 1/60);
          if (!shouldKeep) {
            sceneRef.current.remove(particles);
            particles.geometry.dispose();
            particles.material.dispose();
            delete leafParticlesRef.current[id];
          }
        }
      });

      animationFrameRef.current = requestAnimationFrame(animate);
    };
    animate();

    return () => {
      cancelAnimationFrame(animationFrameRef.current);
      Object.values(treeMeshesRef.current).forEach(treeMesh => {
        if (treeMesh) {
          sceneRef.current.remove(treeMesh);
          if (treeMesh.geometry) treeMesh.geometry.dispose();
          if (treeMesh.material) {
            if (Array.isArray(treeMesh.material)) {
              treeMesh.material.forEach(material => material.dispose());
            } else {
              treeMesh.material.dispose();
            }
          }
        }
      });
      Object.values(leafParticlesRef.current).forEach(particles => {
        if (particles) {
          sceneRef.current.remove(particles);
          if (particles.geometry) particles.geometry.dispose();
          if (particles.material) particles.material.dispose();
        }
      });
    };
  }, [trees, sceneRef, arenaSizeRef, leafParticlesRef]);

  return null;
};

export default TreeComponent;