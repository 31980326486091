import * as THREE from 'three';

export const createPlayerMesh = (id, color) => {
  // Create a parent container for the entire player object
  const playerContainer = new THREE.Group();
  playerContainer.name = `player-container-${id}`;

  // Create the car group
  const carGroup = new THREE.Group();
  carGroup.name = `player-car-${id}`;
  
  // Car body
  const bodyGeometry = new THREE.BoxGeometry(7, 1.5, 4);
  const bodyMaterial = new THREE.MeshPhongMaterial({ 
    color: new THREE.Color(color)
  });
  const bodyMesh = new THREE.Mesh(bodyGeometry, bodyMaterial);
  bodyMesh.position.y = 1;
  bodyMesh.castShadow = true;
  carGroup.add(bodyMesh);

  // Car roof (slightly smaller than body)
  const roofGeometry = new THREE.BoxGeometry(3, 1, 3.5);
  const roofMaterial = new THREE.MeshPhongMaterial({ color: 0x333333 });
  const roofMesh = new THREE.Mesh(roofGeometry, roofMaterial);
  roofMesh.position.set(-0.5, 2.25, 0);
  roofMesh.castShadow = true;
  carGroup.add(roofMesh);

  // Front windshield
  const windshieldGeometry = new THREE.PlaneGeometry(1.2, 3.3);
  const windshieldMaterial = new THREE.MeshPhongMaterial({ color: 0x87CEFA, transparent: true, opacity: 0.7 });
  const windshieldMesh = new THREE.Mesh(windshieldGeometry, windshieldMaterial);
  windshieldMesh.position.set(2, 2, 0);
  windshieldMesh.rotation.y = Math.PI * 0.5;
  windshieldMesh.rotation.x = -Math.PI * 0.1;
  windshieldMesh.castShadow = true;
  carGroup.add(windshieldMesh);

  // Wheels
  const wheelGeometry = new THREE.CylinderGeometry(0.75, 0.75, 0.5, 32);
  const wheelMaterial = new THREE.MeshPhongMaterial({ color: 0x333333 });
  const wheelPositions = [
    { x: 2.5, y: 0.75, z: 2 },
    { x: 2.5, y: 0.75, z: -2 },
    { x: -2.5, y: 0.75, z: 2 },
    { x: -2.5, y: 0.75, z: -2 }
  ];

  wheelPositions.forEach(position => {
    const wheelMesh = new THREE.Mesh(wheelGeometry, wheelMaterial);
    wheelMesh.rotation.x = Math.PI / 2;
    wheelMesh.position.set(position.x, position.y, position.z);
    wheelMesh.castShadow = true;
    carGroup.add(wheelMesh);
  });

  // Add car group to the player container
  playerContainer.add(carGroup);

  // Indicating arrow
  const arrowShape = new THREE.Shape();
  arrowShape.moveTo(1, 0);       // Right point (tip of the arrow)
  arrowShape.lineTo(-1.5, 0.866); // Top left point
  arrowShape.lineTo(-1.5, -0.866); // Bottom left point
  arrowShape.lineTo(1, 0);       // Back to right point to close the shape

  const extrudeSettings = {
    steps: 1,
    depth: 0.2,
    bevelEnabled: false
  };

  const arrowGeometry = new THREE.ExtrudeGeometry(arrowShape, extrudeSettings);
  const arrowMaterial = new THREE.MeshPhongMaterial({
    color: 0xffffff,
    emissive: 0xffffff,
    emissiveIntensity: 0.5
});
  const arrowMesh = new THREE.Mesh(arrowGeometry, arrowMaterial);
  arrowMesh.scale.set(5, 5, 5);
  arrowMesh.rotation.x = -Math.PI / 2; // Lay flat on the ground
  arrowMesh.rotation.z = -0.5;
  arrowMesh.castShadow = true;

  // Add arrow directly to the player container
  playerContainer.add(arrowMesh);
  
  playerContainer.userData = {
    velocityX: 0,
    velocityZ: 0,
    input: { directionX: 0, directionZ: 0, accelerating: false },
    carMesh: carGroup,
    arrowMesh: arrowMesh
  };

    return playerContainer;
  };

export const updatePlayerMesh = (player, state, arenaSize) => {
  const { mesh } = player;
  const worldX = state.x - arenaSize.width / 2;
  const worldZ = -(state.z - arenaSize.height / 2);

  // Use lerp for smoother position updates of the entire player container
  mesh.position.lerp(new THREE.Vector3(worldX, state.elevation, worldZ), 0.2);
  
  const carMesh = mesh.userData.carMesh;
  
  // Smoothly interpolate the rotation of the car mesh
  const targetRotation = state.angle;
  const rotationDiff = targetRotation - carMesh.rotation.y;
  
  // Ensure the rotation difference is between -PI and PI
  const normalizedRotationDiff = Math.atan2(Math.sin(rotationDiff), Math.cos(rotationDiff));
  
  carMesh.rotation.y += normalizedRotationDiff * 0.2;

  // Apply banana effect (wiggle) to the car mesh
  if (state.isBananaEffect) {
    carMesh.rotation.y += (Math.random() - 0.5) * 0.1;
  }

  // Update velocity information in userData
  mesh.userData.velocityX = state.speed * Math.cos(state.angle);
  mesh.userData.velocityZ = state.speed * Math.sin(state.angle);

  // Update arrow direction based on input
  const arrowMesh = mesh.userData.arrowMesh;
  if (arrowMesh) {
    const { directionX, directionZ } = mesh.userData.input;
    if (directionX !== 0 || directionZ !== 0) {
      const angle = Math.atan2(-directionZ, directionX);
      arrowMesh.rotation.z = -angle; // Rotate around z-axis to point in the correct direction
      arrowMesh.visible = true;
      
      // Calculate the distance from the car based on input magnitude
      const inputMagnitude = Math.sqrt(directionX * directionX + directionZ * directionZ);
      const distance = 10 + inputMagnitude * 30; // Adjust these values as needed
      
      arrowMesh.position.set(
        Math.cos(angle) * distance,
        0.11, // Slightly above the ground
        Math.sin(angle) * distance
      );
    } else {
      arrowMesh.visible = false;
    }
  }

  player.lastPosition.set(worldX, 0, worldZ);
};