import { useRef, useEffect } from 'react';
import { createBananaMesh } from './utils/bananaUtils';

const BananaComponent = ({ bananas, sceneRef, arenaSizeRef, socket }) => {
  const bananasRef = useRef({});

  useEffect(() => {
    if (!sceneRef.current || !arenaSizeRef.current) return;

    const handleBananaRemove = ({ bananaId }) => {
      if (bananasRef.current[bananaId]) {
        sceneRef.current.remove(bananasRef.current[bananaId]);
        delete bananasRef.current[bananaId];
      }
    };

    socket.on('bananaRemove', handleBananaRemove);

    return () => {
      socket.off('bananaRemove', handleBananaRemove);
    };
  }, [sceneRef, socket]);

  useEffect(() => {
    if (!sceneRef.current || !arenaSizeRef.current) return;

    bananas.forEach(banana => {
      if (!bananasRef.current[banana.id]) {
        const bananaMesh = createBananaMesh();
        const worldX = banana.x - arenaSizeRef.current.width / 2;
        const worldZ = -(banana.y - arenaSizeRef.current.height / 2);
        bananaMesh.position.set(worldX, 0.5, worldZ);
        bananasRef.current[banana.id] = bananaMesh;
        sceneRef.current.add(bananaMesh);
      }
    });

    // Remove bananas that no longer exist in the game state
    Object.keys(bananasRef.current).forEach(bananaId => {
      if (!bananas.find(b => b.id === bananaId)) {
        sceneRef.current.remove(bananasRef.current[bananaId]);
        delete bananasRef.current[bananaId];
      }
    });

    return () => {
      Object.values(bananasRef.current).forEach(bananaMesh => {
        sceneRef.current.remove(bananaMesh);
      });
    };
  }, [bananas, sceneRef, arenaSizeRef]);

  return null;
};

export default BananaComponent;