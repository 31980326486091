import React, { useEffect, useRef } from 'react';
import * as THREE from 'three';

const BOOSTER_COLOR = 0x6600ff;
const BOOSTER_HEIGHT = 5;
const ARROW_LENGTH = 10;
const ARROW_WIDTH = 5;

const BoosterComponent = ({ track, sceneRef, arenaSizeRef, socket }) => {
  const boostersRef = useRef([]);

  useEffect(() => {
    if (!track || !sceneRef.current || !arenaSizeRef.current) return;

    // Clean up existing boosters
    boostersRef.current.forEach(booster => sceneRef.current.remove(booster));
    boostersRef.current = [];

    // Create new boosters
    track.boosters.forEach((booster, index) => {
      const boosterMesh = createBoosterMesh(booster, index);
      sceneRef.current.add(boosterMesh);
      boostersRef.current.push(boosterMesh);
    });

    return () => {
      boostersRef.current.forEach(booster => sceneRef.current.remove(booster));
    };
  }, [track, sceneRef, arenaSizeRef]);

  useEffect(() => {
    if (!socket) return;

    const handleBoosterActivated = (data) => {
      const boosterMesh = boostersRef.current[data.boosterIndex];
      boosterMesh.castShadow = true;
      if (boosterMesh) {
        animateBoosterActivation(boosterMesh);
      }
    };

    socket.on('boosterActivated', handleBoosterActivated);

    return () => {
      socket.off('boosterActivated', handleBoosterActivated);
    };
  }, [socket]);

  const createBoosterMesh = (booster, index) => {
    const arrowShape = new THREE.Shape();
    
    // Draw the arrow shape
    arrowShape.moveTo(0, -ARROW_WIDTH / 2);
    arrowShape.lineTo(ARROW_LENGTH - ARROW_WIDTH, -ARROW_WIDTH / 2);
    arrowShape.lineTo(ARROW_LENGTH - ARROW_WIDTH, -ARROW_WIDTH);
    arrowShape.lineTo(ARROW_LENGTH, 0);
    arrowShape.lineTo(ARROW_LENGTH - ARROW_WIDTH, ARROW_WIDTH);
    arrowShape.lineTo(ARROW_LENGTH - ARROW_WIDTH, ARROW_WIDTH / 2);
    arrowShape.lineTo(0, ARROW_WIDTH / 2);
    arrowShape.lineTo(0, -ARROW_WIDTH / 2);

    const extrudeSettings = {
      steps: 1,
      depth: BOOSTER_HEIGHT,
      bevelEnabled: false,
    };

    const geometry = new THREE.ExtrudeGeometry(arrowShape, extrudeSettings);
    const material = new THREE.MeshPhongMaterial({ 
      color: BOOSTER_COLOR,
      transparent: true,
      opacity: 0.7,
    });

    const mesh = new THREE.Mesh(geometry, material);

    // Position and rotate the arrow to lay flat on the ground
    mesh.position.set(
      booster.x - arenaSizeRef.current.width / 2,
      BOOSTER_HEIGHT / 2,
      -(booster.z - arenaSizeRef.current.height / 2)
    );
    mesh.rotation.x = -Math.PI / 2; // This rotates the arrow to lay flat
    mesh.rotation.z = booster.angle; // This rotates the arrow to point in the correct direction

    // Add a pulsing effect
    mesh.userData = {
      originalScale: mesh.scale.clone(),
      pulsePhase: Math.random() * Math.PI * 2, // Random starting phase
    };

    return mesh;
  };

  const animateBoosterActivation = (boosterMesh) => {
    const originalColor = boosterMesh.material.color.getHex();
    const originalOpacity = boosterMesh.material.opacity;

    const animate = (time) => {
      const t = (Math.sin(time / 100) + 1) / 2;
      boosterMesh.material.color.setHex(originalColor);
      boosterMesh.material.color.lerp(new THREE.Color(0xffff00), t);
      boosterMesh.material.opacity = originalOpacity + (1 - originalOpacity) * t;

      if (time < 1000) {
        requestAnimationFrame(animate);
      } else {
        boosterMesh.material.color.setHex(originalColor);
        boosterMesh.material.opacity = originalOpacity;
      }
    };

    animate(0);
  };

  // Add a pulsing animation to make boosters more noticeable
  useEffect(() => {
    let animationFrameId;

    const animatePulse = (time) => {
      boostersRef.current.forEach((booster) => {
        const { originalScale, pulsePhase } = booster.userData;
        const scaleFactor = 1 + 0.1 * Math.sin(time * 0.005 + pulsePhase);
        booster.scale.copy(originalScale).multiplyScalar(scaleFactor);
      });

      animationFrameId = requestAnimationFrame(animatePulse);
    };

    animatePulse(0);

    return () => {
      cancelAnimationFrame(animationFrameId);
    };
  }, []);

  return null;
};

export default BoosterComponent;