import { useEffect, useRef } from 'react';
import { updatePlayerTrail } from '../utils/trailUtils';

const useGameLoop = (raceStatus, raceStartTimeRef, setRaceTime, playersRef, sceneRef) => {
  const animationFrameIdRef = useRef(null);

  useEffect(() => {
    let lastUpdateTime = 0;

    const gameLoop = (currentTime) => {
      if (lastUpdateTime === 0) {
        lastUpdateTime = currentTime;
      }

      const deltaTime = currentTime - lastUpdateTime;
      lastUpdateTime = currentTime;

      if (raceStatus === 'racing') {
        setRaceTime(prevTime => prevTime + deltaTime);
      }

      // Update player trails
      Object.entries(playersRef.current).forEach(([playerId, player]) => {
        updatePlayerTrail(player, sceneRef, currentTime);
      });

      animationFrameIdRef.current = requestAnimationFrame(gameLoop);
    };

    if (raceStatus === 'racing') {
      lastUpdateTime = 0;
      animationFrameIdRef.current = requestAnimationFrame(gameLoop);
    }

    return () => {
      if (animationFrameIdRef.current) {
        cancelAnimationFrame(animationFrameIdRef.current);
      }
    };
  }, [raceStatus, raceStartTimeRef, setRaceTime, playersRef, sceneRef]);
};

export default useGameLoop;