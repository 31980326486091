import { useCallback, useRef, useEffect } from 'react';
import * as THREE from 'three'

const useThreeJSSetup = (canvasRef, sceneRef, cameraRef, rendererRef, arenaSizeRef, setIsInitialized, playerMeshRef) => {
  const playerRef = useRef(null);
  const directionalLightRef = useRef(null);
  const lightAngleRef = useRef({ azimuth: Math.PI / 6, elevation: Math.PI / 8 });
  const cameraTargetRef = useRef(new THREE.Vector3());
  const cameraPosRef = useRef(new THREE.Vector3());

  const MIN_LOOK_AHEAD_DISTANCE = 20;
  const MAX_LOOK_AHEAD_DISTANCE = 90;
  const LOOK_AHEAD_FACTOR = 1.5;
  const CAMERA_LERP_FACTOR = 0.05;
  const TARGET_LERP_FACTOR = 0.04;
  const DIRECTION_CHANGE_THRESHOLD = Math.PI / 4; // 45 degrees

  const FIXED_TIME_STEP = 1 / 60; // 60 updates per second
  const MAX_STEPS = 5; // Maximum number of steps to prevent spiral of death
  
  const lastPlayerDirection = useRef(new THREE.Vector3());
  const lastUpdateTime = useRef(0);
  const accumulator = useRef(0);
  const pixelRatio = useRef(1);

  const updatePhysics = (deltaTime) => {
    if (!playerRef.current) return;

    const player = playerRef.current;
    const userData = player.userData;

    // Update velocity based on input
    const acceleration = 200; // Adjust as needed
    const maxSpeed = 230; // Adjust as needed
    const friction = 0.98; // Adjust as needed

    if (userData.accelerating) {
      userData.velocityX += Math.cos(player.rotation.y) * acceleration * deltaTime;
      userData.velocityZ += Math.sin(player.rotation.y) * acceleration * deltaTime;
    }

    // Apply friction
    userData.velocityX *= friction;
    userData.velocityZ *= friction;

    // Limit speed
    const speed = Math.sqrt(userData.velocityX ** 2 + userData.velocityZ ** 2);
    if (speed > maxSpeed) {
      const ratio = maxSpeed / speed;
      userData.velocityX *= ratio;
      userData.velocityZ *= ratio;
    }

    // Update position
    player.position.x += userData.velocityX * deltaTime;
    player.position.z += userData.velocityZ * deltaTime;

    // Keep player within bounds
    const halfWidth = arenaSizeRef.current.width / 2;
    const halfHeight = arenaSizeRef.current.height / 2;
    player.position.x = Math.max(-halfWidth, Math.min(halfWidth, player.position.x));
    player.position.z = Math.max(-halfHeight, Math.min(halfHeight, player.position.z));

    // Update rotation based on input
    const turnSpeed = 3; // Adjust as needed
    if (userData.input) {
      const targetAngle = Math.atan2(userData.input.directionX, -userData.input.directionZ);
      let angleDiff = targetAngle - player.rotation.y;
      
      // Normalize angle difference to be between -PI and PI
      angleDiff = Math.atan2(Math.sin(angleDiff), Math.cos(angleDiff));
      
      player.rotation.y += angleDiff * turnSpeed * deltaTime;
    }

    // Handle collisions (example with simple objects)
    if (sceneRef.current) {
      sceneRef.current.children.forEach(child => {
        if (child.userData.isObstacle) {
          const distance = player.position.distanceTo(child.position);
          if (distance < (userData.radius + child.userData.radius)) {
            // Simple collision response
            const overlap = (userData.radius + child.userData.radius) - distance;
            const direction = new THREE.Vector3().subVectors(player.position, child.position).normalize();
            player.position.add(direction.multiplyScalar(overlap));

            // Reduce velocity
            userData.velocityX *= 0.5;
            userData.velocityZ *= 0.5;
          }
        }
      });
    }

    // Update elevation based on terrain (if applicable)
    player.position.y = getElevationAt(player.position.x, player.position.z);
  };


  const updateCamera = (deltaTime) => {
    if (!playerRef.current) return;

    const playerPosition = playerRef.current.position;
    const playerVelocity = new THREE.Vector3(
      playerRef.current.userData.velocityX,
      0,
      playerRef.current.userData.velocityZ
    );

    // Calculate the look-ahead distance based on player speed and pixel ratio
    const speed = playerVelocity.length();
    let lookAheadDistance = speed * LOOK_AHEAD_FACTOR * pixelRatio.current;
    lookAheadDistance = Math.max(MIN_LOOK_AHEAD_DISTANCE, Math.min(lookAheadDistance, MAX_LOOK_AHEAD_DISTANCE));

    // Calculate the predicted position
    const currentDirection = playerVelocity.clone().normalize();
    const predictedPosition = new THREE.Vector3().addVectors(
      playerPosition,
      currentDirection.multiplyScalar(lookAheadDistance)
    );

    // Check for sudden direction changes
    const directionChange = currentDirection.angleTo(lastPlayerDirection.current);
    const lerpFactor = directionChange > DIRECTION_CHANGE_THRESHOLD 
      ? TARGET_LERP_FACTOR * 0.5 
      : TARGET_LERP_FACTOR;

    // Update camera target with resolution-independent lerp
    cameraTargetRef.current.lerp(predictedPosition, lerpFactor * deltaTime / FIXED_TIME_STEP / pixelRatio.current);

    // Update last player direction
    lastPlayerDirection.current.copy(currentDirection);

    // Calculate desired camera position
    const cameraOffset = new THREE.Vector3(0, 200, 200).multiplyScalar(pixelRatio.current);
    const desiredCameraPos = new THREE.Vector3().addVectors(cameraTargetRef.current, cameraOffset);

    // Smoothly interpolate camera position with resolution-independent lerp
    cameraPosRef.current.lerp(desiredCameraPos, CAMERA_LERP_FACTOR * deltaTime / FIXED_TIME_STEP / pixelRatio.current);

    // Update camera position and look-at
    camera.position.copy(cameraPosRef.current);
    camera.lookAt(cameraTargetRef.current);

    // Update light position relative to camera
    updateLightPosition();
  };

  const animate = (currentTime) => {
    requestAnimationFrame(animate);

    const deltaTime = (currentTime - lastUpdateTime.current) / 1000;
    lastUpdateTime.current = currentTime;

    accumulator.current += deltaTime;

    // Fixed time step update
    let stepsTaken = 0;
    while (accumulator.current >= FIXED_TIME_STEP && stepsTaken < MAX_STEPS) {
      updatePhysics(FIXED_TIME_STEP);
      accumulator.current -= FIXED_TIME_STEP;
      stepsTaken++;
    }

    // If we still have time left in the accumulator, do one last update
    if (accumulator.current > 0 && stepsTaken < MAX_STEPS) {
      const remainingDelta = accumulator.current;
      updatePhysics(remainingDelta);
      accumulator.current = 0;
    }

    // Camera updates can still happen every frame for smoothness
    updateCamera(deltaTime);

    renderer.render(scene, camera);
  };

  useEffect(() => {
    const updatePixelRatio = () => {
      pixelRatio.current = window.devicePixelRatio || 1;
    };

    const handleResize = () => {
      if (cameraRef.current && rendererRef.current) {
        updatePixelRatio();
        const { innerWidth, innerHeight } = window;
        cameraRef.current.aspect = innerWidth / innerHeight;
        cameraRef.current.updateProjectionMatrix();
        rendererRef.current.setSize(innerWidth, innerHeight);
        rendererRef.current.setPixelRatio(pixelRatio.current);
      }
    };

    const handleDeviceOrientation = () => {
      setTimeout(handleResize, 100); // Delay to ensure the resize is complete
    };

    window.addEventListener('resize', handleResize);
    window.addEventListener('orientationchange', handleDeviceOrientation);

    // Initial setup
    updatePixelRatio();
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('orientationchange', handleDeviceOrientation);
    };
  }, []);

  const updateLightPosition = useCallback(() => {
    if (directionalLightRef.current && cameraRef.current) {
      const radius = 1000;
      const azimuth = lightAngleRef.current.azimuth;
      const elevation = lightAngleRef.current.elevation;

      const relativeX = radius * Math.cos(elevation) * Math.cos(azimuth);
      const relativeY = radius * Math.sin(elevation);
      const relativeZ = radius * Math.cos(elevation) * Math.sin(azimuth);

      const cameraPosition = cameraRef.current.position;

      directionalLightRef.current.position.set(
        cameraPosition.x + relativeX * 0.2,
        cameraPosition.y + relativeY,
        cameraPosition.z + relativeZ * 0.2
      );

      const targetDistance = 500;
      const targetX = cameraPosition.x + targetDistance * Math.sin(cameraRef.current.rotation.y);
      const targetZ = cameraPosition.z + targetDistance * Math.cos(cameraRef.current.rotation.y);
      directionalLightRef.current.target.position.set(targetX, 0, targetZ);

      directionalLightRef.current.shadow.camera.updateProjectionMatrix();
    }
  }, []);

  const changeLightAngle = useCallback((newAzimuth, newElevation) => {
    lightAngleRef.current = { azimuth: newAzimuth, elevation: newElevation };
    updateLightPosition();
  }, [updateLightPosition]);

  const updatePlayerReference = useCallback((playerMesh) => {
    playerRef.current = playerMesh;
    playerMeshRef.current = playerMesh;
  }, [playerMeshRef]);

  const initThreeJS = useCallback((arenaSize) => {
    const scene = new THREE.Scene();
    scene.background = new THREE.Color(0xf0f0f0);

    const camera = new THREE.PerspectiveCamera(
      60,
      window.innerWidth / window.innerHeight,
      0.1,
      2000
    );
    camera.position.set(0, 200, 200);
    camera.lookAt(0, 0, 0);

    const renderer = new THREE.WebGLRenderer({ canvas: canvasRef.current, antialias: true });
    renderer.setSize(window.innerWidth, window.innerHeight);
    renderer.shadowMap.enabled = true;
    renderer.shadowMap.type = THREE.PCFSoftShadowMap;

    const arenaGeometry = new THREE.PlaneGeometry(arenaSize.width, arenaSize.height);
    const arenaMaterial = new THREE.MeshPhongMaterial({ color: 0x73ca7c, side: THREE.DoubleSide });
    const arenaMesh = new THREE.Mesh(arenaGeometry, arenaMaterial);
    arenaMesh.rotation.x = -Math.PI / 2;
    arenaMesh.receiveShadow = true;
    scene.add(arenaMesh);

    const ambientLight = new THREE.AmbientLight(0xffffff, 0.5);
    scene.add(ambientLight);

    const directionalLight = new THREE.DirectionalLight(0xffffff, 0.5);
    directionalLight.castShadow = true;
    directionalLight.shadow.mapSize.width = 4096;
    directionalLight.shadow.mapSize.height = 4096;
    directionalLight.shadow.camera.near = 10;
    directionalLight.shadow.camera.far = 3000;
    directionalLight.shadow.camera.left = -arenaSize.width;
    directionalLight.shadow.camera.right = arenaSize.width;
    directionalLight.shadow.camera.top = arenaSize.height;
    directionalLight.shadow.camera.bottom = -arenaSize.height;
    directionalLight.shadow.bias = -0.0005;
    scene.add(directionalLight);
    scene.add(directionalLight.target);

    directionalLightRef.current = directionalLight;
    updateLightPosition();

    // const axesHelper = new THREE.AxesHelper(500);
    // scene.add(axesHelper);

    sceneRef.current = scene;
    cameraRef.current = camera;
    rendererRef.current = renderer;
    arenaSizeRef.current = arenaSize;

    const animate = () => {
      requestAnimationFrame(animate);
  
      // Update camera position to follow player and predict future position
      if (playerRef.current) {
        const playerPosition = playerRef.current.position;
        const playerVelocity = new THREE.Vector3(
          playerRef.current.userData.velocityX,
          0,
          -playerRef.current.userData.velocityZ
        );
  
        // Calculate the look-ahead distance based on player speed
        const speed = playerVelocity.length();
        let lookAheadDistance = speed * LOOK_AHEAD_FACTOR;
        lookAheadDistance = Math.max(MIN_LOOK_AHEAD_DISTANCE, Math.min(lookAheadDistance, MAX_LOOK_AHEAD_DISTANCE));
  
        // Calculate the predicted position
        const currentDirection = playerVelocity.clone().normalize();
        const predictedPosition = new THREE.Vector3().addVectors(
          playerPosition,
          currentDirection.multiplyScalar(lookAheadDistance)
        );
  
        // Check for sudden direction changes
        const directionChange = currentDirection.angleTo(lastPlayerDirection.current);
        if (directionChange > DIRECTION_CHANGE_THRESHOLD) {
          // Gradually adjust the camera target for smooth transition
          cameraTargetRef.current.lerp(predictedPosition, TARGET_LERP_FACTOR * 0.5);
        } else {
          // Normal camera target update
          cameraTargetRef.current.lerp(predictedPosition, TARGET_LERP_FACTOR);
        }
  
        // Update last player direction
        lastPlayerDirection.current.copy(currentDirection);
  
        // Calculate desired camera position
        const cameraOffset = new THREE.Vector3(0, 200, 200);
        const desiredCameraPos = new THREE.Vector3().addVectors(cameraTargetRef.current, cameraOffset);
  
        // Smoothly interpolate camera position
        cameraPosRef.current.lerp(desiredCameraPos, CAMERA_LERP_FACTOR * 2);
  
        // Update camera position and look-at
        camera.position.copy(cameraPosRef.current);
        // camera.lookAt(cameraTargetRef.current);
  
        // Update light position relative to camera
        updateLightPosition();
      }
  
      renderer.render(scene, camera);
    };
    animate();

    const handleResize = () => {
      const width = window.innerWidth;
      const height = window.innerHeight;

      camera.aspect = width / height;
      camera.updateProjectionMatrix();

      renderer.setSize(width, height);
    };

    window.addEventListener('resize', handleResize);

    setIsInitialized(true);

    return () => {
      window.removeEventListener('resize', handleResize);
      renderer.dispose();
    };
  }, [canvasRef, sceneRef, cameraRef, rendererRef, arenaSizeRef, setIsInitialized, updateLightPosition]);

  
  useEffect(() => {
    if (playerRef.current) {
      updateLightPosition();
    }
  }, [updateLightPosition]);

  return { initThreeJS, updatePlayerReference, changeLightAngle };
};

export default useThreeJSSetup;