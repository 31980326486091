import React, { useEffect } from 'react';
import { FaGoogle } from 'react-icons/fa';

const SSOLogin = ({ onLoginSuccess, onLoginFailure }) => {
  useEffect(() => {
    const loadGoogleScript = () => {
      const script = document.createElement('script');
      script.src = 'https://accounts.google.com/gsi/client';
      script.async = true;
      script.defer = true;
      document.body.appendChild(script);

      script.onload = initializeGoogleSignIn;
    };

    const initializeGoogleSignIn = () => {
      if (window.google) {
        window.google.accounts.id.initialize({
          client_id: import.meta.env.VITE_GOOGLE_CLIENT_ID,
          callback: handleCredentialResponse,
        });
      }
    };

    loadGoogleScript();
  }, []);

  const handleCredentialResponse = (response) => {
    const decoded = JSON.parse(atob(response.credential.split('.')[1]));
    const user = {
      id: decoded.sub,
      name: decoded.name,
      email: decoded.email,
    };
    onLoginSuccess(user);
  };

  const handleGoogleSignIn = () => {
    if (window.google) {
      window.google.accounts.id.prompt();
    } else {
      onLoginFailure('Google Sign-In not available');
    }
  };

  return (
    <div className="flex flex-col items-center">
      <h2 className="text-xl font-bold mb-4">Sign in for Leaderboard Tracking</h2>
      <button
        onClick={handleGoogleSignIn}
        className="flex items-center justify-center bg-white text-gray-700 font-semibold py-2 px-4 border border-gray-300 rounded-lg shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
      >
        <FaGoogle className="mr-2" />
        Sign in with Google
      </button>
      <p className="mt-4 text-sm text-gray-600">
        Sign in is optional. You can still play without signing in.
      </p>
    </div>
  );
};

export default SSOLogin;