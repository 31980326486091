import React, { useRef, useState, useEffect, useCallback } from 'react';
import DebugWindow from './DebugWindow';
import TreeComponent from './TreeComponent';
import BananaComponent from './BananaComponent';
import TrackComponent from './TrackComponent';
import BoosterComponent from './BoosterComponent';
import RaceControls from './RaceControls';
import LeaderboardComponent from './LeaderboardComponent';
import InputManager from './InputManager';
import useGameLoop from './hooks/useGameLoop';
import useSocketHandlers from './hooks/useSocketHandlers';
import useThreeJSSetup from './hooks/useThreeJSSetup';

const Game = ({ socket, user }) => {
  const canvasRef = useRef(null);
  const sceneRef = useRef(null);
  const cameraRef = useRef(null);
  const rendererRef = useRef(null);
  const playersRef = useRef({});
  const playerIdRef = useRef(null);
  const arenaSizeRef = useRef(null);
  const playerPositionRef = useRef({ x: 0, y: 0 });
  const leafParticlesRef = useRef({});
  const raceStartTimeRef = useRef(0);
  const playerMeshRef = useRef(null);

  const [isInitialized, setIsInitialized] = useState(false);
  const [trees, setTrees] = useState([]);
  const [bananas, setBananas] = useState([]);
  const [track, setTrack] = useState(null);
  const [raceStatus, setRaceStatus] = useState('notStarted');
  const [raceTime, setRaceTime] = useState(0);
  const [countdown, setCountdown] = useState(0);
  const [nextCheckpoint, setNextCheckpoint] = useState(0);
  const [currentLap, setCurrentLap] = useState(0);
  const [nextRaceStartTime, setNextRaceStartTime] = useState(0);
  const [currentRaceState, setCurrentRaceState] = useState('notStarted');
  const [playerInput, setPlayerInput] = useState({ directionX: 0, directionZ: 0, accelerating: false });
  const [lapTime, setLapTime] = useState(0);

  const { initThreeJS, updatePlayerReference } = useThreeJSSetup(
    canvasRef,
    sceneRef,
    cameraRef,
    rendererRef,
    arenaSizeRef,
    setIsInitialized,
    playerMeshRef
  );

  useSocketHandlers(
    socket,
    initThreeJS,
    setTrees,
    setBananas,
    setTrack,
    setRaceStatus,
    setRaceTime,
    setCountdown,
    raceStartTimeRef,
    playerIdRef,
    playersRef,
    leafParticlesRef,
    sceneRef,
    arenaSizeRef,
    updatePlayerReference,
    setNextCheckpoint,
    setCurrentLap,
    setNextRaceStartTime,
    setCurrentRaceState,
    setLapTime
  );

  useGameLoop(raceStatus, raceStartTimeRef, setRaceTime, playersRef, sceneRef);

  const handleInputChange = useCallback((input) => {
    if (socket && isInitialized) {
      socket.emit('updatePlayerDirection', input);
      setPlayerInput(input);

      // Update the player mesh with the new input
      if (playerMeshRef.current) {
        playerMeshRef.current.userData.input = input;
      }
    }
  }, [socket, isInitialized]);

  useEffect(() => {
    if (user && socket) {
      socket.emit('login', user);
    }
  }, [user, socket]);

  useEffect(() => {
    if (nextRaceStartTime > 0) {
      const timeUntilNextRace = nextRaceStartTime - Date.now();
      if (timeUntilNextRace > 0) {
        const timer = setTimeout(() => {
          setRaceStatus('countdown');
          setCountdown(3); // 3 seconds countdown
        }, timeUntilNextRace);
        return () => clearTimeout(timer);
      }
    }
  }, [nextRaceStartTime]);

  useEffect(() => {
    const handleCountdownStarted = ({ duration }) => {
      setRaceStatus('countdown');
      setCountdown(Math.ceil(duration / 1000));
    };

    socket.on('countdownStarted', handleCountdownStarted);

    return () => {
      socket.off('countdownStarted', handleCountdownStarted);
    };
  }, [socket, setRaceStatus, setCountdown]);

  return (
    <>
      <canvas ref={canvasRef} style={{ width: '100vw', height: '100vh' }} />
      <TreeComponent
        trees={trees}
        sceneRef={sceneRef}
        arenaSizeRef={arenaSizeRef}
        leafParticlesRef={leafParticlesRef}
        socket={socket}
      />
      <BananaComponent
        bananas={bananas}
        sceneRef={sceneRef}
        arenaSizeRef={arenaSizeRef}
        socket={socket}
      />
      <TrackComponent
        track={track}
        sceneRef={sceneRef}
        arenaSizeRef={arenaSizeRef}
        nextCheckpoint={nextCheckpoint}
      />
      <BoosterComponent
        track={track}
        sceneRef={sceneRef}
        arenaSizeRef={arenaSizeRef}
        socket={socket}
      />
      <LeaderboardComponent socket={socket} user={user} />
      <DebugWindow
        players={playersRef.current}
        currentPlayerId={playerIdRef.current}
        playerPosition={playerPositionRef.current}
        trees={trees}
        bananas={bananas}
        checkpoints={track ? track.checkpoints : []}
        currentCheckpoint={nextCheckpoint}
        currentLap={currentLap}
        raceStatus={raceStatus}
        raceTime={raceTime}
        nextRaceStartTime={nextRaceStartTime}
        currentRaceState={currentRaceState}
        playerInput={playerInput}
      />
      <RaceControls
        socket={socket}
        raceStatus={raceStatus}
        raceTime={raceTime}
        lapTime={lapTime}
        countdown={countdown}
        nextRaceStartTime={nextRaceStartTime}
        currentRaceState={currentRaceState}
      />
      <InputManager onInputChange={handleInputChange} />
    </>
  );
};

export default Game;