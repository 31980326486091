import * as THREE from 'three';

export const createTreeMesh = () => {
  try {
    const treeGroup = new THREE.Group();

    // Tree trunk
    const trunkGeometry = new THREE.CylinderGeometry(0.2, 0.4, 2, 8);
    const trunkMaterial = new THREE.MeshPhongMaterial({ color: 0x8B4513 });
    const trunkMesh = new THREE.Mesh(trunkGeometry, trunkMaterial);
    trunkMesh.position.y = 1;
    trunkMesh.castShadow = true;
    treeGroup.add(trunkMesh);

    // Tree foliage
    const foliageGeometry = new THREE.ConeGeometry(1, 3, 8);
    const foliageMaterial = new THREE.MeshPhongMaterial({ color: 0x228B22 });
    const foliageMesh = new THREE.Mesh(foliageGeometry, foliageMaterial);
    foliageMesh.position.y = 2.5;
    foliageMesh.castShadow = true;
    treeGroup.add(foliageMesh);

    treeGroup.castShadow = true;

    return treeGroup;
  } catch (error) {
    console.error("Error creating tree mesh:", error);
    return null;
  }
};

export const createLeafParticles = (position) => {
  const particleCount = 50;
  const geometry = new THREE.BufferGeometry();
  const positions = new Float32Array(particleCount * 3);
  const colors = new Float32Array(particleCount * 3);

  for (let i = 0; i < particleCount; i++) {
    positions[i * 3] = Math.random() * 5 - 2.5;
    positions[i * 3 + 1] = Math.random() * 5 + 5;
    positions[i * 3 + 2] = Math.random() * 5 - 2.5;

    colors[i * 3] = 0.1 + Math.random() * 0.5; // Red
    colors[i * 3 + 1] = 0.5 + Math.random() * 0.5; // Green
    colors[i * 3 + 2] = 0.1; // Blue
  }

  geometry.setAttribute('position', new THREE.BufferAttribute(positions, 3));
  geometry.setAttribute('color', new THREE.BufferAttribute(colors, 3));

  const material = new THREE.PointsMaterial({
    size: 0.1,
    vertexColors: true,
    transparent: true,
    opacity: 0.8,
  });

  const particles = new THREE.Points(geometry, material);
  particles.position.copy(position);
  
  particles.userData = {
    velocities: Array(particleCount).fill().map(() => new THREE.Vector3(
      (Math.random() - 0.5) * 0.1,
      -Math.random() * 0.1 - 0.05,
      (Math.random() - 0.5) * 0.1
    )),
    creationTime: Date.now()
  };

  return particles;
};

export const animateLeaves = (particles, deltaTime) => {
  const positions = particles.geometry.attributes.position.array;
  const velocities = particles.userData.velocities;

  for (let i = 0; i < positions.length; i += 3) {
    positions[i] += velocities[i / 3].x * deltaTime;
    positions[i + 1] += velocities[i / 3].y * deltaTime;
    positions[i + 2] += velocities[i / 3].z * deltaTime;

    // Apply "gravity" and some horizontal drift
    velocities[i / 3].y -= 0.01 * deltaTime;
    velocities[i / 3].x += (Math.random() - 0.5) * 0.01 * deltaTime;
    velocities[i / 3].z += (Math.random() - 0.5) * 0.01 * deltaTime;

    // Reset particles that fall below a certain height
    if (positions[i + 1] < 0) {
      positions[i + 1] = Math.random() * 5 + 5;
      velocities[i / 3].y = 0;
    }
  }

  particles.geometry.attributes.position.needsUpdate = true;

  // Fade out particles over time
  const age = (Date.now() - particles.userData.creationTime) / 1000;
  particles.material.opacity = Math.max(0, 1 - age / 5);

  return age < 5; // Return false when particles should be removed
};