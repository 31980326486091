import React, { useState, useEffect, useCallback } from 'react';
import { Joystick } from 'react-joystick-component';

const InputManager = ({ onInputChange }) => {
  const [input, setInput] = useState({ directionX: 0, directionZ: 0, accelerating: false });

  // Keyboard state
  const [keyState, setKeyState] = useState({
    up: false,
    down: false,
    left: false,
    right: false
  });

  // Joystick state
  const [joystickState, setJoystickState] = useState({ x: 0, y: 0 });

  const handleKeyDown = useCallback((event) => {
    let newState = { ...keyState };
    switch (event.key) {
      case 'w':
      case 'ArrowUp':
        newState.up = true;
        break;
      case 'a':
      case 'ArrowLeft':
        newState.left = true;
        break;
      case 's':
      case 'ArrowDown':
        newState.down = true;
        break;
      case 'd':
      case 'ArrowRight':
        newState.right = true;
        break;
      default:
        return;
    }
    setKeyState(newState);
  }, [keyState]);

  const handleKeyUp = useCallback((event) => {
    let newState = { ...keyState };
    switch (event.key) {
      case 'w':
      case 'ArrowUp':
        newState.up = false;
        break;
      case 'a':
      case 'ArrowLeft':
        newState.left = false;
        break;
      case 's':
      case 'ArrowDown':
        newState.down = false;
        break;
      case 'd':
      case 'ArrowRight':
        newState.right = false;
        break;
      default:
        return;
    }
    setKeyState(newState);
  }, [keyState]);

  const handleJoystickMove = useCallback((event) => {
    setJoystickState({ x: event.x, y: -event.y });
  }, []);

  const handleJoystickStop = useCallback(() => {
    setJoystickState({ x: 0, y: 0 });
  }, []);

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    window.addEventListener('keyup', handleKeyUp);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('keyup', handleKeyUp);
    };
  }, [handleKeyDown, handleKeyUp]);

  useEffect(() => {
    let directionX = 0;
    let directionZ = 0;

    // Combine keyboard and joystick inputs
    if (keyState.up || joystickState.y > 0) directionZ += 1;
    if (keyState.down || joystickState.y < 0) directionZ -= 1;
    if (keyState.left || joystickState.x < 0) directionX -= 1;
    if (keyState.right || joystickState.x > 0) directionX += 1;

    // If joystick is active, use its values directly
    if (joystickState.x !== 0 || joystickState.y !== 0) {
      directionX = joystickState.x;
      directionZ = -joystickState.y; // Invert Y axis for joystick
    }

    // Normalize the direction vector
    const length = Math.sqrt(directionX * directionX + directionZ * directionZ);
    if (length > 0) {
      directionX /= length;
      directionZ /= length;
    }

    const accelerating = length > 0;

    const newInput = { directionX, directionZ, accelerating };
    setInput(newInput);
    onInputChange(newInput);
  }, [keyState, joystickState, onInputChange]);

  return (
    <div style={{ position: 'absolute', bottom: '20px', left: '20px' }}>
      <Joystick
        size={100}
        baseColor="#666666"
        stickColor="#888888"
        move={handleJoystickMove}
        stop={handleJoystickStop}
      />
    </div>
  );
};

export default InputManager;