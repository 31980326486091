import * as THREE from 'three';

export const createBananaMesh = () => {
  const bananaGroup = new THREE.Group();

  // Banana body
  const bodyGeometry = new THREE.CylinderGeometry(0.75, 0.75, 3, 8, 1, false, 0, Math.PI);
  const bodyMaterial = new THREE.MeshPhongMaterial({ color: 0xFFFF00 });
  const bodyMesh = new THREE.Mesh(bodyGeometry, bodyMaterial);
  bodyMesh.rotation.z = Math.PI / 2;
  bodyMesh.castShadow = true;
  bananaGroup.add(bodyMesh);

  // Banana ends
  const endGeometry = new THREE.SphereGeometry(0.75, 8, 8, 0, Math.PI);
  const endMesh1 = new THREE.Mesh(endGeometry, bodyMaterial);
  endMesh1.position.y = 1.5;
  endMesh1.rotation.x = Math.PI / 2;
  endMesh1.castShadow = true;
  bananaGroup.add(endMesh1);

  const endMesh2 = new THREE.Mesh(endGeometry, bodyMaterial);
  endMesh2.position.y = -1.5;
  endMesh2.rotation.x = -Math.PI / 2;
  endMesh2.castShadow = true;
  bananaGroup.add(endMesh2);

  bananaGroup.castShadow = true;

  return bananaGroup;
};

export const animateBananaDrop = (banana, startPosition, endPosition, onComplete) => {
  const animationDuration = 1000; // 1 second
  const startTime = Date.now();

  const animate = () => {
    const now = Date.now();
    const progress = Math.min((now - startTime) / animationDuration, 1);

    // Easing function for a more natural drop
    const easeOutBounce = (x) => {
      const n1 = 7.5625;
      const d1 = 2.75;
      if (x < 1 / d1) {
        return n1 * x * x;
      } else if (x < 2 / d1) {
        return n1 * (x -= 1.5 / d1) * x + 0.75;
      } else if (x < 2.5 / d1) {
        return n1 * (x -= 2.25 / d1) * x + 0.9375;
      } else {
        return n1 * (x -= 2.625 / d1) * x + 0.984375;
      }
    };

    const easedProgress = easeOutBounce(progress);

    banana.position.x = startPosition.x + (endPosition.x - startPosition.x) * easedProgress;
    banana.position.y = startPosition.y + (endPosition.y - startPosition.y) * easedProgress;
    banana.position.z = startPosition.z + (endPosition.z - startPosition.z) * easedProgress;

    if (progress < 1) {
      requestAnimationFrame(animate);
    } else {
      onComplete();
    }
  };

  animate();
};