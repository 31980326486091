import React, { useState, useEffect } from 'react';
import Game from './Game';
import SSOLogin from './SSOLogin';
import io from 'socket.io-client';
import "./index.css";

const colors = [
  'red', 'blue', 'green', 'yellow', 'purple', 'orange', 'pink', 'cyan'
];

const App = () => {
  const [gameStarted, setGameStarted] = useState(false);
  const [playerColor, setPlayerColor] = useState(colors[0]);
  const [playerNickname, setPlayerNickname] = useState('');
  const [socket, setSocket] = useState(null);
  const [user, setUser] = useState(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    const newSocket = io(window.location.origin);
    setSocket(newSocket);

    return () => newSocket.close();
  }, []);

  const handleStartGame = () => {
    if (socket && playerNickname) {
      socket.emit('setPlayerData', { color: playerColor, nickname: playerNickname });
      if (user && !isLoggedIn) {
        socket.emit('login', { ...user, nickname: playerNickname });
        setIsLoggedIn(true);
      }
      setGameStarted(true);
    }
  };

  const handleLoginSuccess = (userData) => {
    const userWithNickname = { ...userData, nickname: playerNickname };
    setUser(userWithNickname);
    if (socket && !isLoggedIn) {
      socket.emit('login', userWithNickname);
      setIsLoggedIn(true);
    }
  };

  const handleLoginFailure = (error) => {
    console.error('Login failed:', error);
  };

  if (gameStarted && socket) {
    return <Game socket={socket} user={user} />;
  }

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <div className="bg-white p-8 rounded-lg shadow-md">
        <h1 className="text-4xl font-bold mb-6 text-center">PolyRacer</h1>
        <div className="mb-6">
          <label htmlFor="nickname-input" className="block text-sm font-medium text-gray-700 mb-2">
            Enter your nickname:
          </label>
          <input
            id="nickname-input"
            type="text"
            value={playerNickname}
            onChange={(e) => setPlayerNickname(e.target.value)}
            className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            placeholder="Your nickname"
            required
          />
        </div>
        <div className="mb-6">
          <label htmlFor="color-select" className="block text-sm font-medium text-gray-700 mb-2">
            Choose your car color:
          </label>
          <select
            id="color-select"
            value={playerColor}
            onChange={(e) => setPlayerColor(e.target.value)}
            className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
          >
            {colors.map((color) => (
              <option key={color} value={color}>
                {color.charAt(0).toUpperCase() + color.slice(1)}
              </option>
            ))}
          </select>
        </div>
        {!user && (
          <div className="mb-6">
            <SSOLogin onLoginSuccess={handleLoginSuccess} onLoginFailure={handleLoginFailure} />
          </div>
        )}
        {user && (
          <div className="mb-6">
            <p className="text-green-600">Logged in as: {user.name}</p>
          </div>
        )}
        <button
          onClick={handleStartGame}
          disabled={!playerNickname}
          className="w-full bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 transition-colors disabled:bg-gray-400 disabled:cursor-not-allowed"
        >
          Start Game
        </button>
      </div>
    </div>
  );
};

export default App;