import { useEffect, useRef } from 'react';
import * as THREE from 'three';

const TRACK_WIDTH = 5;
const TRACK_HEIGHT = 0;
const CHECKPOINT_RADIUS = 25;
const CHECKPOINT_HEIGHT = 1.3;
const START_FINISH_HEIGHT = 2;
const START_FINISH_WIDTH = 100;

const TrackComponent = ({ track, sceneRef, arenaSizeRef, nextCheckpoint }) => {
  const trackRef = useRef(null);
  const checkpointMeshesRef = useRef([]);
  const checkpointEffectsRef = useRef([]);

  useEffect(() => {
    if (!track || !sceneRef.current || !arenaSizeRef.current) return;

    const { width, height } = arenaSizeRef.current;

    const trackGroup = new THREE.Group();

    // Create a smooth curve passing through all checkpoints
    const curve = createSmoothTrackCurve(track.checkpoints, width, height);

    // Generate road mesh along the curve
    const roadMesh = createRoadMesh(curve, width, height);
    trackGroup.add(roadMesh);

    // Create checkpoint markers
    checkpointMeshesRef.current = track.checkpoints.map((checkpoint, index) => {
      const checkpointMesh = createCheckpointMesh(checkpoint, index === 0, width, height);
      trackGroup.add(checkpointMesh);
      return checkpointMesh;
    });

    // Add start line
    const startMesh = createStartFinishMesh(track.checkpoints[0], track.checkpoints[1], width, height, 0x00FF00);
    trackGroup.add(startMesh);

    // Add finish line
    const finishMesh = createStartFinishMesh(track.checkpoints[track.checkpoints.length - 1], track.checkpoints[0], width, height, 0xFF0000);
    trackGroup.add(finishMesh);

    if (trackRef.current) {
      sceneRef.current.remove(trackRef.current);
    }

    trackRef.current = trackGroup;
    sceneRef.current.add(trackGroup);

    return () => {
      if (trackRef.current) {
        sceneRef.current.remove(trackRef.current);
      }
    };
  }, [track, sceneRef, arenaSizeRef]);

  useEffect(() => {
    if (checkpointMeshesRef.current.length > 0) {
      checkpointMeshesRef.current.forEach((mesh, index) => {
        if (index === nextCheckpoint) {
          mesh.material.color.setHex(0xFFA500); // Orange color
          createCheckpointEffect(index);
        } else if (index === 0) {
          mesh.material.color.setHex(0x00FF00); // Green for start
        } else if (index === checkpointMeshesRef.current.length - 1) {
          mesh.material.color.setHex(0xFF0000); // Red for finish
        } else {
          mesh.material.color.setHex(0x00FF00); // Green for other checkpoints
        }
      });
    }
  }, [nextCheckpoint]);

  const createSmoothTrackCurve = (checkpoints, arenaWidth, arenaHeight) => {
    const curvePoints = checkpoints.map(cp => new THREE.Vector2(cp.x, cp.z));
    const curve = new THREE.SplineCurve(curvePoints);
    return curve;
  };

  const createRoadMesh = (curve, arenaWidth, arenaHeight) => {
    const points = curve.getPoints(200);
    const shape = new THREE.Shape();
    shape.moveTo(-TRACK_WIDTH / 10, -50);
    shape.lineTo(TRACK_WIDTH / -10, 50);

    const geometry = new THREE.ExtrudeGeometry(shape, {
      steps: 200,
      bevelEnabled: false,
      extrudePath: new THREE.CatmullRomCurve3(
        points.map(p => new THREE.Vector3(p.x - arenaWidth / 2, TRACK_HEIGHT, -(p.y - arenaHeight / 2)))
      )
    });

    const material = new THREE.MeshPhongMaterial({ color: 0x6b6349 });
    const mesh = new THREE.Mesh(geometry, material);
    mesh.receiveShadow = true;
    return mesh;
  };

  const createCheckpointMesh = (checkpoint, isStartOrFinish, arenaWidth, arenaHeight) => {
    const geometry = new THREE.CircleGeometry(CHECKPOINT_RADIUS, 32);
    const material = new THREE.MeshBasicMaterial({ 
      color: isStartOrFinish ? (checkpoint.isStart ? 0x00FF00 : 0xFF0000) : 0x0000FF, 
      transparent: true, 
      opacity: 0.5,
      side: THREE.DoubleSide
    });
    const mesh = new THREE.Mesh(geometry, material);
    mesh.position.set(
      checkpoint.x - arenaWidth / 2,
      CHECKPOINT_HEIGHT / 2 + TRACK_HEIGHT,
      -(checkpoint.z - arenaHeight / 2)
    );
    mesh.rotation.x = -Math.PI / 2;
    mesh.receiveShadow = true;
    return mesh;
  };

  const createStartFinishMesh = (checkpoint, nextCheckpoint, arenaWidth, arenaHeight, color) => {
    const geometry = new THREE.BoxGeometry(START_FINISH_WIDTH, START_FINISH_HEIGHT, TRACK_WIDTH);
    const material = new THREE.MeshPhongMaterial({
      color: color,
      emissive: color,
      emissiveIntensity: 0.5,
    });
    const mesh = new THREE.Mesh(geometry, material);
    
    // Calculate direction vector between checkpoints
    const direction = new THREE.Vector2(
      nextCheckpoint.x - checkpoint.x,
      nextCheckpoint.z - checkpoint.z
    ).normalize();
    
    // Position the mesh
    mesh.position.set(
      checkpoint.x - arenaWidth / 2,
      START_FINISH_HEIGHT / 2, // Remove TRACK_HEIGHT and adjust if necessary
      -(checkpoint.z - arenaHeight / 2)
    );
    mesh.rotation.y = checkpoint.angle;
    mesh.receiveShadow = true;
    return mesh;
  };

  const createCheckpointEffect = (index) => {
    if (checkpointEffectsRef.current[index]) {
      sceneRef.current.remove(checkpointEffectsRef.current[index]);
    }

    const checkpoint = track.checkpoints[index];
    const effectGeometry = new THREE.RingGeometry(CHECKPOINT_RADIUS, CHECKPOINT_RADIUS + 5, 32);
    const effectMaterial = new THREE.MeshBasicMaterial({ 
      color: 0xFFA500,
      side: THREE.DoubleSide,
      transparent: true,
      opacity: 0.7
    });
    const effectMesh = new THREE.Mesh(effectGeometry, effectMaterial);
    
    effectMesh.position.set(
      checkpoint.x - arenaSizeRef.current.width / 2,
      CHECKPOINT_HEIGHT + TRACK_HEIGHT + 0.1, // Slightly above the checkpoint
      -(checkpoint.z - arenaSizeRef.current.height / 2)
    );
    effectMesh.rotation.x = -Math.PI / 2; // Lay flat on the ground

    sceneRef.current.add(effectMesh);
    checkpointEffectsRef.current[index] = effectMesh;

    // Animate the effect
    const startTime = Date.now();
    const animate = () => {
      const elapsedTime = Date.now() - startTime;
      const scale = 1 + Math.sin(elapsedTime * 0.01) * 0.2;
      effectMesh.scale.set(scale, scale, 1);

      if (elapsedTime < 1000) {
        requestAnimationFrame(animate);
      } else {
        sceneRef.current.remove(effectMesh);
        checkpointEffectsRef.current[index] = null;
      }
    };
    animate();
  };

  return null;
};

export default TrackComponent;