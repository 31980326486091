import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';

const LeaderboardComponent = ({ socket }) => {
  const [leaderboard, setLeaderboard] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [playerPosition, setPlayerPosition] = useState(null);

  useEffect(() => {
    const handleLeaderboardUpdate = (updatedLeaderboard) => {
      setLeaderboard(updatedLeaderboard);
      const playerEntry = updatedLeaderboard.find(entry => entry.id === socket.id);
      if (playerEntry) {
        setPlayerPosition(playerEntry.position);
      } else {
        setPlayerPosition(null);
      }
    };

    const handleLapCompleted = ({ playerId, position }) => {
      if (playerId === socket.id) {
        setPlayerPosition(position);
      }
    };

    socket.on('leaderboardUpdate', handleLeaderboardUpdate);
    socket.on('lapCompleted', handleLapCompleted);

    return () => {
      socket.off('leaderboardUpdate', handleLeaderboardUpdate);
      socket.off('lapCompleted', handleLapCompleted);
    };
  }, [socket]);

  const formatTime = (time) => {
    if (!time || time === Infinity) return '--:--:---';
    const minutes = Math.floor(time / 60000);
    const seconds = ((time % 60000) / 1000).toFixed(3);
    return `${minutes}:${seconds.padStart(6, '0')}`;
  };

  const leaderboardVariants = {
    open: { y: '75%' },  // Move up to show 25% of the screen
    closed: { y: 'calc(100% - 40px)' }, // Leave the toggle button visible
  };

  return (
    <motion.div
      className="fixed top-0 right-0 bottom-0 w-80 max-w-full flex flex-col bg-black bg-opacity-80 shadow-lg"
      initial="closed"
      animate={isOpen ? "open" : "closed"}
      variants={leaderboardVariants}
      transition={{ type: 'spring', stiffness: 300, damping: 30 }}
    >
      <button
        className="bg-blue-500 text-white px-4 py-2 w-full text-left font-medium"
        onClick={() => setIsOpen(!isOpen)}
      >
        {isOpen ? 'Hide' : 'Show'} Leaderboard
        <span className="float-right">
          Your Position: {playerPosition !== null ? playerPosition : '-'} / {leaderboard.length}
        </span>
      </button>

      <div className="flex-grow overflow-auto p-4">
        <table className="w-full text-white">
          <thead>
            <tr className="border-b border-gray-600">
              <th className="text-left py-2">Pos</th>
              <th className="text-left py-2">Color</th>
              <th className="text-right py-2">Best Lap</th>
            </tr>
          </thead>
          <tbody>
            {leaderboard.map((player) => (
              <tr key={player.id} className={`border-b border-gray-700 ${player.id === socket.id ? 'bg-blue-500 bg-opacity-50' : ''}`}>
                <td className="py-1">{player.position}</td>
                <td className="py-1">
                  <div
                    className="w-3 h-3 rounded-full"
                    style={{ backgroundColor: player.color }}
                  ></div>
                </td>
                <td className="text-right py-1">{formatTime(player.bestLapTime)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </motion.div>
  );
};

export default LeaderboardComponent;